(function (window, document, $) {
    $.SocialShare = function (elem, e, shareDataSelector, shareDataElement) {

        var constants = {
            facebookAppId: "508089859627973",
            facebookUrl: "https://www.facebook.com/dialog/share?",
            twitterUrl: "https://twitter.com/intent/tweet?",
            linkedInUrl: "https://www.linkedin.com/shareArticle?"
        };

        var openTab = function (url) {
            elem.attr("href", url);
            elem.attr("target", "_blank");
        };

        var popup = function (type, url) {

            var w, h;
            switch (type) {
                case "twitter":
                    w = 550;
                    h = 500;
                    break;
                case "facebook":
                    w = 670;
                    h = 400;
                    break;
                default:
                    w = 500;
                    h = 500;
                    break;
            }
            var x = (screen.width / 2) - (w / 2);
            var y = (screen.height / 2) - (h / 2);

            var popupConfig = "width=" + w + ",height=" + h + ",left=" + x + ",top=" + y + ",";
            popupConfig += "resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=yes";
            var newWindow = window.open(url, "popup", popupConfig);
            if (!newWindow) {
                console.log("Unable to open popup, most likely due to a popup blocker!");
                openTab(url);
            } else {
                e.preventDefault();
            }
        };

        var urlBuilders = {
            'facebook': function (shareData) {
                var requestData = {
                    'app_id': constants.facebookAppId,
                    'display': "popup",
                    'href': shareData.url,
                    'title': shareData.title,
                    'description': shareData.description,
                    'picture': shareData.media
                };

                var params = $.param(requestData);
                return constants.facebookUrl + params;
            },
            'twitter': function (shareData) {
                var requestData = {
                    'text': shareData.title,
                    'url': shareData.url
                };

                var params = $.param(requestData);
                return constants.twitterUrl + params;
            },
            'linked-in': function (shareData) {
                var requestData = {
                    'mini': "true",
                    'url': shareData.url,
                    'title': shareData.title,
                    'summary': shareData.description
                };

                var params = $.param(requestData);
                return constants.linkedInUrl + params;
            }
        };


        var getShareData = function () {
            var description = elem.data("description");
            var type = elem.data("type");
            var url = elem.data("url");
            return {
                type: type,
                title: elem.data("title"),
                description: description,
                media: elem.data("media"),
                url: url
            };
        };

        var getUrlForShareData = function (shareData) {
            var builder = urlBuilders[shareData.type];
            if (!builder)
                return null;

            return builder(shareData);
        };

        function showShare(type, url, shareData) {
            if (type === "print") {
                e.preventDefault();
                window.print();
                return;
            }
            if (type === "email") {
                var email = {
                    subject: shareData.title,
                    body: encodeURI(shareData.url)
                };
                window.open("mailto:?subject=" + email.subject + "&body=" + email.body, "_self");
                e.preventDefault();
                return;
            }

            popup(type, url);
        };

        function init() {
            var shareData = getShareData();
            var shareUrl = getUrlForShareData(shareData);
            showShare(shareData.type, shareUrl, shareData);
        };

        init();
    };

    $.fn.socialShare = function (e, shareDataSelector, shareDataElement) {

        if (!$.data(this, "share-button")) {
            var socialShare = new $.SocialShare(this, e, shareDataSelector, shareDataElement);
            this.data("share-button", socialShare);
        }
        return this.data("share-button");
    };

    $(function () {

        $(document).on('click', ".share-button", function (e) {
            $(this).socialShare(e, ".share-button");
        });
    });

}(window, document, jQuery));